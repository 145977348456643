// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { EnvironmentSalesAgentShared } from '../../../../../../libs/env/src/index';

export const env: EnvironmentSalesAgentShared = {
  static: {
    expId: '2860',
    partnerId: '2860',
    title: 'Amfam Advance Sales Portal',
  },
  afiAppname: 'amfam-excl-agent', // TODO Update the value to 'amfam-adv-agent' once we have api's configured with advance values
  afiApiKey: '00db3987-fdb1-4195-897c-1f6f7ac3c894', // TODO Update once we have AMFAM Advance info available
  azureClientId: '8ce4e9ba-cb39-4e11-8753-3a5472775d5e',
  azureAuthority: 'https://login.microsoftonline.com/df80d506-c0a6-479d-ac62-b94a4a2013d9',
  azureRedirectEndpoint: 'agent/login',
  amfamBillingAfiAppname: 'ecp-ui-sales-agent',
  sapiClientCredentials: {
    '2860': {},
  },
};
