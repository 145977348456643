import { merge } from '@ecp/utils/common';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type {
  EnvironmentSalesAgent,
  EnvironmentSalesAgentShared,
} from '../../../../../../libs/env/src/index';
import { env as envSalesDev } from '../env.dev';
import { env as baseEnv } from './env';

/** DON'T USE IN ANY MODULES. THIS IS WHAT `@ecp/env` ALIAS RESOLVES TO AT BUILD TIME. */
export const env: EnvironmentSalesAgent = merge({}, envSalesDev, baseEnv, {
  optimizely: {
    sdkKey: 'GgVXwHuLEqReFGDbnC7qc',
  },
  ecpDalRoot: 'https://dal.ecp-dev.homesitep2.com/v1',
  /**
   * https://amfament.atlassian.net/wiki/spaces/EAB/pages/16346821692/Script+Sources
   * if ecpDalRoot is changed, this must also change to match the script that is associated to the DAL API environment
   */
  botControlScriptSrc:
    'https://f2572c50661d.us-east-1.captcha-sdk.awswaf.com/f2572c50661d/jsapi.js',
  botControlEnabled: true,
  amfamBillingApiRoot: 'https://billingint01.amfam.com',
  advancePolicyCenterRedirectUrl: 'https://plpc-int01.amfam.com/pc/GatewayPortal.do',
  agentToolingApiRoot: 'https://dal.ecp-dev.homesitep2.com/api/agent-tooling/v1',
} as EnvironmentSalesAgentShared) as EnvironmentSalesAgent;
